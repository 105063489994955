<template>
    <r-e-drawer class="drawer-tenant-info" title="租户详情" :visible.sync="drawerVisible" :size="1700" modal
                wrapper-closable drawer-wrapper>
        <div class="drawer-container">
            <div class="container-content" style="display: flex">
                <div style="width: 30%;height: 100%;padding: 15px;border: 1px solid #cfcfcf;">
                    <div class="drawer-title flex align-center" style="font-size: 24px;margin-bottom: 15px;">
                        {{ tenantData.name }}
                        <el-button type="primary" size="mini" @click="updateTenant" style="margin-left: 10px">编辑租户信息</el-button>
                        <el-button type="primary" size="mini" @click="balancerecharge" style="margin-left: 10px" v-if="getButtonMenu('BALANCERECHARGE')">余额充值</el-button>
                    </div>
                    <div style="padding: 15px;border: 1px solid #d6e9c6;border-radius: 2px;margin-bottom: 10px;display: flex;justify-content: space-between">
                        <div>
                            <div style="font-size: 18px;color: #36a9e0;">0</div>
                            <div>欠费</div>
                        </div>
                        <div>
                            <div style="font-size: 18px;color: #36a9e0;">0</div>
                            <div>维修</div>
                        </div>
                        <div>
                            <div style="font-size: 18px;color: #36a9e0;">0</div>
                            <div>提醒</div>
                        </div>
                    </div>
                    <div class="drawer-title" style="font-size: 14px;padding: 10px 0;">
                        房租余额 : {{ rentalBalance }}元 杂费余额 : {{ expenseBalance }}元 押金 : {{ depoBalance }}元
                    </div>
                    <div>
                        <r-e-table class="bg-white" ref="depositLogRef" :dataRequest="depositLog"
                                   :columns="rechargeRecordColumns" rowKeyId="uuidOrder" :query="formSearch"
                                   :small="true" :height="550" show-summary :summary-method="getSummaries">
                            <template slot="empty">
                                <el-empty/>
                            </template>
                            <!--<el-table-column slot="toolbar" label="操作" fixed="right" width="150">
                                <template slot-scope="{ row }">
                                    <div class="table-tools">
                                        <el-button size="mini" @click="invoice(row,700001)">收据</el-button>
                                        <el-button size="mini" @click="invoice(row,700002)">发票</el-button>
                                    </div>
                                </template>
                            </el-table-column>-->
                        </r-e-table>
                    </div>
                </div>
                <div style="width: 70%;height: 100%;border: 1px solid #cfcfcf;border-left: 0;overflow:hidden;">
                    <div style="display: flex;height: 50px;background-color: rgb(241, 241, 241)">
                        <div style="padding: 15px;" v-for="item in tenantInfoTabOptions" :key="item.name"
                             :style="{backgroundColor:item.name === activeTab?'#fff':''}" @click="activeTab = item.name"
                        >
                            {{item.label}}
                        </div>
                    </div>
                    <div style="height: 90%;">
                        <div class="container-content hidden-scrollbar" style="padding: 15px;margin-top: 0">
                            <component :is="activeTab" :tenantData="tenantData" @getDeposit="getDeposit"
                                       @handleSearch="handleSearch" @closeDrawer="closeDrawer"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <dialog-save-tenant ref="dialogSaveTenant" @closeDrawer="closeDrawer"></dialog-save-tenant>
      <!--  余额充值组件-->
      <dialogrecharge ref="dialogrechargeref" @getDeposit="getDeposit"></dialogrecharge>
        <!--<r-e-dialog :title="title" :visible.sync="billSwitch" show-footer top="5vh" width="1000px" :showFooter="false"
                    @click-cancel="billListClose" v-dialog-drag>
            <r-e-table ref="tableRef" :columns="houseBillColumns" :data="billList" :height="500" :showPagination="false"
                       show-summary :summary-method="getSummaries">
                <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
            </r-e-table>
        </r-e-dialog>-->
    </r-e-drawer>
</template>

<script>
    import {getDeposit} from "@/api/house-resource";
    import {numberFormat} from "@custom";
    import {rechargeRecordColumns} from "@/views/rental-management/tenant-management/data";
    import {depositLog} from "@/api/tenant-management";
    import {invoicePrint, invoice} from "@/api/invoice-management";
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    import {buttonCookies} from "@storage/cookies";
    export default {
        name: "drawer-tenant-info",
        data() {
            return {
                drawerVisible: false,
                tenantData: {},
                activeTab: 'baseInfo',
                tenantInfoTabOptions: [
                    {
                        label: "承租人详情",
                        name: "baseInfo"
                    }, {
                        label: "房租",
                        name: "bill"
                    }, {
                        label: "其他费用",
                        name: "other"
                    }, {
                        label: "消费记录",
                        name: "call"
                    }, {
                        label: "账单记录",
                        name: "retur"
                    },{
                        label: "水电",
                        name: "hydropower"
                    },{
                        label: "报修管理",
                        name: "Repair"
                    }

                ],
                expenseBalance: 0,
                rentalBalance: 0,
                depoBalance: 0,
                rechargeRecordColumns,
                formSearch: {},
            }
        },
        components: {
            baseInfo: () => import("./part/baseInfo"),
            bill: () => import("./part/bill"),
            other: () => import("./part/other"),
            call: () => import("./part/call"),
            retur: () => import("./part/retur"),
            hydropower:() => import("./part/hydropower"),
            Repair:() => import("./part/Repair"),
            dialogSaveTenant: () => import("./dialog-save-tenant"),
            dialogrecharge:() => import("./dialog-balancerecharge")
        },
        methods: {
            depositLog(params) {
                let {uuid} = this.tenantData;
                params.uuid = uuid;
                return depositLog(params);
            },
            openDrawer(row) {
                this.tenantData = row;
                this.getDeposit();
                this.drawerVisible = true;
            },
            closeDrawer() {
                this.$emit('handleSearch');
                this.drawerVisible = false;
            },
            async getDeposit() {
                let paramData = {uuid: this.tenantData.uuid};
                const res = await getDeposit(paramData).catch(err => {
                });
                if (res) {
                    let {info: {expense_balance, rental_balance, depo_balance}} = res;
                    this.expenseBalance = numberFormat(expense_balance / 100);
                    this.rentalBalance = numberFormat(rental_balance / 100);
                    this.depoBalance = numberFormat(depo_balance / 100);
                }
            },
            handleSearch() {
                this.$refs["depositLogRef"].getTableData();
            },
            updateTenant() {
                let {...data} = this.tenantData;
                this.$refs["dialogSaveTenant"].openDialog(data);
            },
            invoice(data, type) {
                let {uuid} = data;
                let that = this;
                this.$confirm('此操作将申请该条' + (type === 700001 ? '收据' : '发票') + ',是否继续?', '注意', {
                    type: 'warning'
                }).then(async () => {
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    invoice({uuid, type}).then(res => {
                        MessageSuccess("申请成功");
                        loading.close();
                        if (type === 700001) {
                            that.invoicePrint(uuid);
                        }
                        that.handleSearch();
                    }).catch(err => loading.close());
                }).catch(() => {});
            },
            invoicePrint(uuid) {
                const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                invoicePrint(uuid).then(res => {
                    if (res) {
                        const binaryData = [];
                        binaryData.push(res);
                        let pdfUrl = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
                        let tempwindow = window.open("", '_blank');
                        tempwindow.location = pdfUrl;
                    }
                    loading.close();
                }).catch(err => {
                    loading.close();
                });
            },

            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === columns.length - 1) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.map(item => {
                        const {label} = column;
                        const {rental_amount, expense_amount, depo_amount} = item;
                        return label==="金额"? Number(rental_amount + expense_amount + depo_amount): Number(item[column.property]);
                    });
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] = numberFormat(sums[index] / 100);
                    } else sums[index] = '';

                });
                return sums;
            },
          //   陈守亮-2023-12-18-新增余额充值
          balancerecharge() {
            this.$refs.dialogrechargeref.ruleForm.cancelTime =  new Date().toLocaleString()
            this.$refs.dialogrechargeref.dialogVisible = true
            this.$refs.dialogrechargeref.tenantData = this.tenantData
          },
          //获取按钮权限数组
          getButtonMenu(menu) {
            return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
          },
        },
        watch: {
            drawerVisible(value) {
                // if (!value) this.closeDrawer();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .drawer-tenant-info {
        .drawer-header {
            .room-status {
                color: #1F7CF6;
                font-size: rem(14px);
                border-radius: 15px;
                padding: VH(3px) VW(10px);
                background: #CEE7FF;
                margin-left: VW(10px);
            }
        }

        .drawer-container {
            margin-left: VW(5px);

            .container-header {
                width: fit-content;
                border-radius: 4px;
                box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

                .item {
                    padding: VH(15px) VW(30px);
                    color: #666;
                    font-size: rem(16px);
                    cursor: pointer;
                    border-radius: 4px;
                    margin-right: VW(5px);

                    &:last-child {
                        margin-right: 0;
                    }

                    &.is-active-tab,
                    &:hover {
                        color: #fff;
                        background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                        box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                    }
                }
            }

            .container-content {
                height: VH(870px);
                margin-top: VH(20px);
            }
        }

        /deep/ .container-content {
            .table-title {
                position: relative;
                padding-left: 10px;
                margin: 20px 0;

                &::before {
                    width: 5px;
                    height: 100%;
                    content: '';
                    background-color: #F5A623;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
</style>
